.lc-survey-main {
  background-color: #f1f1f1;
}
.left-pane {
  height: 100vh;
  width: 522px;
  position: fixed;
  left: 0;
  background-color: $sidebarBg;
  overflow-x: auto;
}
.right-pane {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  background-color: #e4f0f1;
  // padding-left: 572px;
  padding: 50px 50px 40px 580px;
  &.no-pane {
    padding: calc(1rem + 2vw);
  }
}
// .no-pane {
//   padding: 72px 72px 50px 72px;
// }

.ls-select [class*="-control"] {
  background-color: hsl(0, 0%, 100%);
  border-color: #fff !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 54px !important;
}
.ls-select {
  .css-1pahdxg-control {
    box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.07);
  }
  .css-1wa3eu0-placeholder {
    color: var(--bs-body-color);
  }
}

.sidebar {
  padding: 40px 45px;
  .lcs-logo {
    margin-bottom: 2.75vh;
  }
  .sidebar-text-sml {
    color: #9f9f9f;
    font-size: 18px;
    line-height: 0.97;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 6.5vh;
    span {
      color: #fff;
      line-height: inherit;
      font-weight: 700;
    }
  }
  .sidebar-text-mid {
    color: #fff;
    font-size: 38px;
    font-weight: 300;
    line-height: 1;
    span {
      color: inherit;
      font-weight: 400;
      line-height: inherit;
      display: block;
    }
  }
  .four-boxes {
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .four-box-item {
      background-color: #848687;
      padding: 18px;
      width: 48.70%;
      height: 190px;
      // margin-bottom: 10px;
      border-radius: 9px;
      -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        inset 0px 3px 6px 0px rgba(0, 0, 0, 0.11);
      -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        inset 0px 3px 6px 0px rgba(0, 0, 0, 0.11);
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        inset 0px 3px 6px 0px rgba(0, 0, 0, 0.11);
      cursor: pointer;
      .box-icon {
        padding: 0;
        margin: 0;
        position: relative;
        img {
          position: relative;
          z-index: 111;
          max-width: 108px;
          height: auto;
        }
      }
      .box-text {
        padding: 0;
        font-size: 16px;
        color: $white;
        font-weight: 700;
        line-height: 1;
        z-index: 0;
        text-align: center;
      }
    }
  }

  .share-block {
    margin-top: 2vh;
    .share-text {
      color: #9f9f9f;
      font-size: 19px;
      font-weight: 400;
      line-height: 0.97;
      font-family: Arial, Helvetica, sans-serif;
      max-width: 250px;
      line-height: 1.2;
    }    
  }
}

.lc-survey-form {
  .lcs-form {
    max-width: 1300px;
  }
  .form-control {
    border: none;
    min-height: 54px;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.07);
    }
  }
  .form-select {
    border: none;
    min-height: 60px;
    padding: 1.35rem 0.75rem;
    line-height: 1;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.07);
    }
  }
}

@include media-breakpoint-down(xxl) { 
  .left-pane {
    height: 100vh;
    width: 415px;
}
.right-pane{
  &.no-pane {
    padding: calc(1.25rem + 2vw);
  }
}
  .right-pane {
    padding: 30px 35px 30px 445px;
  }
  .sidebar {
    padding: calc(1rem + 2vw);
    .sidebar-text-sml {
      font-size: calc(1rem + 0.25vw);
      margin-top: 3.5vh;
  }
    .sidebar-text-mid {
      font-size: calc(1.5rem + 1vw);
    }
    .share-block {
      .share-text {
        font-size: calc(1rem + 0.25vw);
        font-weight: 400;
        max-width: 250px;
      }
    }
    .four-boxes {
      .four-box-item {
       padding: 15px;
        width: 49%;
        height: 165px;
        .box-icon{
          img{
            width: 100%;
            max-width: 80px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xl) { 
  .left-pane {
    width: 380px;
  }
  .right-pane {
    padding: 40px 40px 40px 415px;
  }
  .sidebar{
    .lcs-logo {
      margin-bottom: 1.5vh;
      img{
        width: 100%;
        max-width: 250px;
      }
  }
  .four-boxes {
    .four-box-item {
     padding: 15px;
      width: 48%;
      height: 150px;
      .box-icon {
        img{
        max-width: 74px;
        }
      }
    }
  }
}
}

@include media-breakpoint-down(lg) {
  .left-pane {
    height: auto;
    width: 100%;
    position: static;
    // overflow-x: auto;
    text-align: center;
  }
  .right-pane {
    padding: 25px;
    min-height: 1px;
  }
  .sidebar {
    padding: calc(1rem + 1.25vw);
    .sidebar-text-mid {
      span {
        display: inline;
      }
    }
    .share-block {
      .share-text {
        max-width: 100%;
      }
    }
    .four-boxes {
      max-width: 700px;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      .four-box-item {
        width: 24%;
        height: 160px;
        .box-icon{
          max-width: 90px;
          img{
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  .lc-survey-form {
    .lcs-form {
      max-width: 100%;
    }
  }
}

@include media-breakpoint-down(md) {
  .sidebar {
    .four-boxes {
      max-width: 700px;
     
      margin-left: auto;
      margin-right: auto;
      .four-box-item {
        width: 48%;
        height: 140px;
        .box-icon{
          max-width: 80px;
        }
      }
    }
  }
  .welcome-main {
    .bottom-row {
      .text-r {
        font-size: 24px;
    }
    }
    .welcome-logo {
      img {
        width: 100%;
        max-width: 300px;
      }
    }
  }
}

@media (max-width: 360px) {
  .welcome-main {
    .welcome-logo {
      img {
        width: 100%;
        max-width: 260px;
      }
    }
  }
}