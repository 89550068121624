.skip-main {
  height: 100vh;
  width: 71.8%;
  position: fixed;
  top: 0;
  left: 28.2%;
  backdrop-filter: blur(3px);
  // backdrop-filter: opacity(20%);
  background-color: #00000085;
  z-index: 111;

  .skip-child-1 {
    position: absolute;
    top: 30%;
    left: 27%;
    z-index: 1;

    .skip-child-2 {
      height: 350px;
      width: 660px;
      background: #e4f0f1;
      display: flex;
      border-radius: 10px;
      align-items: center;
      justify-content: center;
      padding: 40px;
      flex-direction: column;
      position: relative;
      text-align: center;
      z-index: 1;

      .skip-child-3 {
        h4 {
          text-align: center;
          line-height: 2.5rem;
        }
        button {
          margin-top: 25px;
          // margin-right: 40px;
        }
      }
    }
  }
}
.topright-img {
  border-radius: 0 10px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 11;
  max-width: 100px;
  width: 17vw;
}


@media only screen and (max-width: 1440px) {
  .skip-main {
    height: 100%;
    width: 100%;
    top: 0;
    left: 36.2%;
    .skip-child-1 {
      position: absolute;
      top: 30%;
      left: 9.4%;
      z-index: 1;

      .skip-child-2 {
        height: 350px;
        width: 660px;
        // margin: 30px;

        .skip-child-3 {
          h4 {
            text-align: center;
            line-height: 2.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .skip-main {
    height: 100%;
    width: 100%;
    top: 0;
    left: 32.3%;
    .skip-child-1 {
      position: absolute;
      top: 30%;
      left: 5.9%;
      z-index: 1;

      .skip-child-2 {
        height: 350px;
        width: 660px;
        // margin: 30px;

        .skip-child-3 {
          h4 {
            text-align: center;
            line-height: 2.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .skip-main {
    height: 100%;
    width: 100%;
    top: 0;
    left: 37.1%;
    .skip-child-1 {
      position: absolute;
      top: 30%;
      left: 0;
      z-index: 1;
      margin: 15px;

      .skip-child-2 {
        height: 350px;
        width: 61.9%;
        // margin: 30px;

        .skip-child-3 {
          h4 {
            text-align: center;
            line-height: 2.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .skip-main {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    .skip-child-1 {
      position: absolute;
      top: 30%;
      // left: 24px;
      z-index: 1;

      .skip-child-2 {
        height: 350px;
        width: 91%;
        margin: 30px;

        .skip-child-3 {
          h4 {
            text-align: center;
            line-height: 2.5rem;
          }
          button {
            // margin-right: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .skip-main {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    .skip-child-1 {
      position: absolute;
      top: 20%;
      left: 0;
      z-index: 1;

      .skip-child-2 {
        height: 100%;
        width: 100%;
        margin: 0px;

        .skip-child-3 {
          h4 {
            text-align: center;
            line-height: 2.5rem;
          }
          button {
            // margin-right: 0px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .skip-main {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    .skip-child-1 {
      position: absolute;
      top: 30%;
      left: 0;
      z-index: 1;

      .skip-child-2 {
        height: 100%;
        width: 90%;
        margin: 15px;
        padding: 20px;

        .skip-child-3 {
          h4 {
            text-align: center;
            line-height: 2.5rem;
          }
          button {
            // margin: 10px !important;
          }
        }
      }
    }
  }
}
