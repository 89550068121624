.chart-block {
  .chart-item {
    position: relative;
    .num {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      font-size: 28px;
      font-weight: 700;
      color: #383f48;
      transform: translateY(-50%);
    }
    svg {
      width: 125px;
      height: 125px;
      transform: rotate(-90deg);
      g {
        fill: none;
        stroke-dasharray: 100;
      }
      circle {
        fill: none;
      }
      .g1 {
        stroke-width: 4;
        // stroke:#46BBC5;
      }
      .g2 {
        stroke-width: 4.1;
        stroke: #fff;
      }
    }
  }
}
.overall-score {
  align-items: center;
  background-color: white;
  border-radius: 50px;
  .text-mid {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 700;
    font-size: 28px;
    line-height: 1;
  }
  .text-l {
    padding: 21px;
    font-size: 22px;
    font-weight: 700;
    line-height: 1;
    border-radius: 0 50px 50px 0;
  }
}

.sub-title {
  font-size: 28px;
  font-weight: 700;
}

.congratulations-main {
  .chart-block {
    line-height: 1.1;
    strong{
      line-height: inherit;
    }
    & + .chart-block {
      margin-left: 2.7vw;
    }
  }
}

@include media-breakpoint-down(xxl) {
  .overall-score {
    .text-mid {
      font-size: 24px;
    }
    .text-l {
      font-size: 20px;
      padding: 15px 15px 10px 10px;
      min-height: 50px;
  }
  .icon{
    img{
      width: 50px;
      height: 50px;
    }
  }
  }
}
@include media-breakpoint-down(xl) {
  .congratulations-main {

    .chart-block {
      & + .chart-block {
        margin-left: 2.5vw;
      }
      .chart-item {
        .num{
          font-size: 22px;
        }
        svg {
          width: 100px;
          height: 100px;
        }
      }
    }
    
  }
}

@include media-breakpoint-down(lg) {
  
  .overall-score {
    .text-mid {
      font-size: 18px;
      margin-left: 8px;
      margin-right: 8px;
    }
    .text-l {
      font-size: 16px;
      padding: 14px 15px 8px 10px;
      min-height: 42px;
  }
  .icon{
    img{
      width: 42px;
      height: 42px;
    }
  }
  }
  .congratulations-main {
    .chart-block {
      .chart-item {
        svg {
          width: 120px;
          height: 120px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
 
  .congratulations-main {
    .chart-block {
      .chart-item {
        svg {
          width: 90px;
          height: 90px;
        }
      }
    }
  }
  .btn-primary-small,.btn-2,.btn-primary{
    padding: 5px 15px;
    font-size: 18px;
  }

}



@media (max-width: 640px) {
  .overall-score {
    .text-mid {
      font-size: 12px;
      margin-left: 8px;
      margin-right: 8px;
    }
    .text-l {
      font-size: 12px;
      padding: 12px 12px 4px 8px;
      min-height: 36px;
  }
  .icon{
    img{
      width: 36px;
      height: 36px;
    }
  }
  }
  .congratulations-main {
    .dougnut-charts{
      flex-direction: row;
      flex-wrap: wrap;
    }
    .chart-block {
      // width: 40%; 
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 40px;
      & + .chart-block {
        margin-left: 10px;
      }
      strong{
        display: block!important;
        min-height: 38px;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .download-main{
    .page-title {
      font-size: calc(1.5rem + 1vw);
    }
    .dl-sub-text {
      font-size: calc(1.5rem + 1vw);
  }
  .mid-text {
    font-size: calc(1.25rem + 0.75vw);
    line-height: 1.2;
}
  }
}