@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;700&display=swap");

@import "variables";
@import "~bootstrap/scss/bootstrap.scss";
@import "loader";
@import "welcome";
@import "user-form";
@import "questions";
@import "invite-form";
@import "score-details";
@import "congratulations";
@import "skip";

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

 .row-gap {
  row-gap: 10px;
 }

 @media (max-width: 440px) {
 .btn-primary {
    width: 50%;
  }
  .sharebtn, .backbtn {
    width: 100%;
    padding: 0.563rem  1.905rem;
  }
  .sharestartbtn {
    flex-direction: column;
  }

  .four-boxes {
    justify-content: center;
  }

 }
